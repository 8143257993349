/* eslint-disable */
function serialisePoint(point) {
  return { lng: point[0], lat: point[1] };
}
function serialisePolyline(coordinates) {
  return coordinates.map(serialisePoint);
}
function serialisePolygon(coordinates) {
  const polygon = {};
  coordinates.forEach((polyline, index) => {
    polygon[index] = serialisePolyline(polyline);
  })
  return polygon;
}
function serialiseMultiPolygon(coordinates) {
  const multiPolygon = {};
  coordinates.forEach((polygon, index) => {
    multiPolygon[index] = serialisePolygon(polygon);
  });
  return multiPolygon;
}

function deserialisePoint(point) {
  return [point.lng, point.lat];
}
function deserialisePolyline(coordinates) {
  return coordinates.map(deserialisePoint);
}
function deserialisePolygon(coordinates) {
  return Object.values(coordinates).map(deserialisePolyline);
}
function deserialiseMultiPolygon(coordinates) {
  return Object.values(coordinates).map(deserialisePolygon);
}

function serialiseGeoJSON(feature) {
  switch (feature.type) {
    case "Polyline":
      feature.coordinates = serialisePolyline(feature.coordinates);
      break;
    case "Polygon":
      feature.coordinates = serialisePolygon(feature.coordinates);
      break;
    case "MultiPolygon":
      feature.coordinates = serialiseMultiPolygon(feature.coordinates);
      break;
  }
  return feature;
}
function deserialiseGeoJSON(feature) {
  switch (feature.type) {
    case "Polyline":
      feature.coordinates = deserialisePolyline(feature.coordinates);
      break;
    case "Polygon":
      feature.coordinates = deserialisePolygon(feature.coordinates);
      break;
    case "MultiPolygon":
      feature.coordinates = deserialiseMultiPolygon(feature.coordinates);
      break;
  }
  return feature;
}

class LatLngBounds {
  constructor(sw, ne) {
    this.sw = sw || { lat: NaN, lng: NaN };
    if (!ne)
      ne = sw ? sw : { lat: NaN, lng: NaN };
    this.ne = ne;
  }

  intersects(bounds) {
    return (
      this.sw.lat <= bounds.ne.lat &&
      this.ne.lat >= bounds.sw.lat &&
      this.sw.lng <= bounds.ne.lng &&
      this.ne.lng >= bounds.sw.lng
    );
  }

  containsLatLng(lat, lng) {
    return lat >= this.sw.lat && lat <= this.ne.lat && lng >= this.sw.lng && lng <= this.ne.lng;
  }

  extend(point) {
    this.sw = {
      lat: Math.min(this.sw.lat || point.lat, point.lat),
      lng: Math.min(this.sw.lng || point.lng, point.lng)
    };
    this.ne = {
      lat: Math.max(this.ne.lat || point.lat, point.lat),
      lng: Math.max(this.ne.lng || point.lng, point.lng)
    };
    return this;
  }

  getCenter() {
    return {
      lat: (this.sw.lat + this.ne.lat) / 2,
      lng: (this.sw.lng + this.ne.lng) / 2,
    }
  }

  toJSON() {
    return {
      east: this.ne.lng,
      north: this.ne.lat,
      south: this.sw.lat,
      west: this.sw.lng,
    };
  }
};

function calculateLGABounds(geometry) {
  const bounds = new LatLngBounds();
  let line;
  switch (geometry.type) {
    case "Polyline":
      line = geometry.coordinates;
      break;
    case "Polygon":
      line = geometry.coordinates[0];
      break;
    case "MultiPolygon":
      line = geometry.coordinates.flatMap((a) => a[0]);
      break;
  }
  for (const point of line)
    bounds.extend({ lat: point[1], lng: point[0] });
  return bounds;
}

module.exports = {
  serialiseGeoJSON,
  deserialiseGeoJSON,
  LatLngBounds,
  calculateLGABounds,
};